let body = document.querySelector('body');
let burger = document.querySelector('.js-burger');
let burgerClose = document.querySelector('.js-sidebar__close');
let sidebar = document.querySelector('.js-sidebar');
burger.addEventListener('click', () => {
    sidebar.classList.toggle('is-active');
    body.classList.toggle('is-overflow');
});
burgerClose.addEventListener('click', () => {
    sidebar.classList.toggle('is-active');
    body.classList.toggle('is-overflow');
});
